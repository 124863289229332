const artaiMicrosoftEntraConfig = {
  msalConfig: {
    auth: {
      clientId: '99777d8a-f80e-4f16-9fcc-897ff9500a92',
      authority: 'https://login.microsoftonline.com/organizations',
      redirectUri: () => `${location.origin}/granted`,
      postLogoutRedirectUri: () => `${location.origin}/logout`
    }
  },
  apiConfig: {
    clientId: '4c051df7-8124-4ca9-b31a-87808af6a4c8',
    scopes: ['api://4c051df7-8124-4ca9-b31a-87808af6a4c8/user_impersonations']
  },
  protectedResources: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    [`${location.origin}`, ['api://4c051df7-8124-4ca9-b31a-87808af6a4c8/user_impersonations']]
  ])
};

export { artaiMicrosoftEntraConfig };
